<template>
    <b-sidebar id="add-new-visit-sidebar" :visible="isEditVisitSidebarActive" bg-variant="white" sidebar-class="sidebar-lg"
        shadow backdrop no-header right @hidden="resetForm"
        @change="(val) => $emit('update:is-edit-visit-sidebar-active', val)">
        <template #default="{ hide }">
            <!-- Header -->
            <div class="
          d-flex
          justify-content-between
          align-items-center
          content-sidebar-header
          px-2
          py-1
        ">
                <h5 class="mb-0">Edit Visit</h5>

                <i class="fas fa-times ml-1 cursor-pointer" @click="hide"></i>
            </div>

            <!-- BODY -->
            <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
                <!-- Form -->
                <b-form class="p-2" @submit.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm">
                    <!-- Full Name -->
                    <validation-provider #default="validationContext" name="name" rules="required">
                        <b-form-group :label="$t('Form.Name')" label-for="name">
                            <b-form-input id="name" v-model="stateData.name" autofocus
                                :state="getValidationState(validationContext)" trim placeholder="Visit" />

                            <b-form-invalid-feedback>
                                {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>

                    <validation-provider #default="validationContext" name="prefix" rules="required">
                        <b-form-group label="Prefix" label-for="prefix">
                            <b-form-input id="name" v-model="stateData.prefix" autofocus
                                :state="getValidationState(validationContext)" trim placeholder="Prefix" />
                            <b-form-invalid-feedback>
                                {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>

                    <!-- <validation-provider #default="validationContext" name="prefix" rules="required">
                        <b-form-group label="Color" label-for="color">
                            <input type="color" v-model.trim="stateData.color" class="form-control" trim
                                :state="getValidationState(validationContext)" placeholder="#fff" />
                        </b-form-group>
                    </validation-provider> -->

                    <!-- status -->
                    <validation-provider #default="validationContext" name="Status" rules="required">
                        <b-form-group :label="$t('Form.Status')" label-for="status">
                            <treeselect id="status" v-model="stateData.status"
                                :state="getValidationState(validationContext)" :options="optionsStatus"
                                :normalizer="normalizer" />
                            <b-form-invalid-feedback>
                                {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>

                    <!-- Price -->
                    <!-- <validation-provider #default="validationContext" name="Price" rules="required">
                        <b-form-group label="Price" label-for="Price">
                            <treeselect id="name" v-model="stateData.consult_prices_id" :async="true"
                                :load-options="searchFilter" :defaultOptions="priceOptions" :normalizer="normalizerPrice" />
                            <b-form-invalid-feedback>
                                {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider> -->

                    <!-- Form Actions -->
                    <div class="d-flex mt-2">
                        <b-button variant="primary" class="mr-2" type="submit" :disabled="loading">
                            <span v-if="!loading">Edit</span>
                            <span v-else>
                                <SpinnerLoading />
                            </span>
                        </b-button>
                        <b-button type="button" variant="outline-danger" @click="hide">
                            {{ $t("Cancel") }}
                        </b-button>
                    </div>
                </b-form>
            </validation-observer>
        </template>
    </b-sidebar>
</template>

<script>
import {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
} from "bootstrap-vue";
import formValidation from "@/core/utils/forms/form-validation";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import SpinnerLoading from "@/components/SpinnerLoading";

import { required } from "@validations";
import { ref } from "@vue/composition-api";

import axiosCV from "@/core/services/apiInsurance/admin/consults/insuranceName";

export default {
    components: {
        BSidebar,
        BForm,
        BFormGroup,
        BFormInput,
        BFormInvalidFeedback,
        BButton,
        SpinnerLoading,

        // Form Validation
        ValidationProvider,
        ValidationObserver,
    },
    model: {
        prop: "isEditVisitSidebarActive",
        event: "update:is-edit-visit-sidebar-active",
    },
    props: {
        isEditVisitSidebarActive: {
            type: Boolean,
            required: true,
        },
        itemEdit: {
            type: Object,
            required: true,
            default: () => ({}),
        },
        prices: {
            type: Array,
            required: false,
            default: () => [],
        },
    },
    data() {
        return {
            required,
            normalizer(node) {
                return {
                    id: node.id,
                    label: node.name,
                };
            },
            normalizerPrice(node) {
                return {
                    id: node.id,
                    label: `${node.name} - ${node.price}`,
                };
            },
        };
    },
    setup(props, { emit }) {
        let xd = props.prices
        const searchFilter = ({ action, searchQuery, callback }) => {
            if (action === "ASYNC_SEARCH") {
                const datos = {
                    name: searchQuery,
                };
            }
        }
        const optionsStatus = [
            { name: "Active", id: true },
            { name: "Inactive", id: false },
        ];
        const stateData = ref(JSON.parse(JSON.stringify(props.itemEdit.value)));

        const priceOptions = ref(xd)
        const resetuserData = () => {
            stateData.value = JSON.parse(JSON.stringify(props.itemEdit.value));
        };

        const loading = ref(false);
        const onSubmit = () => {
            loading.value = true;
            const VisitItem = JSON.parse(JSON.stringify(stateData));
            axiosCV
                .visitUpdate(VisitItem.value.id, VisitItem.value)
                .then(() => {
                    loading.value = false;
                    resetuserData();
                    emit("editVisitSuccess", true);
                })
                .catch(() => {
                    emit("editVisitSuccess", false);
                    loading.value = false;
                });
        };
        const { refFormObserver, getValidationState, resetForm } = formValidation(
            resetuserData
        );
        return {
            stateData,
            loading,
            optionsStatus,
            onSubmit,
            refFormObserver,
            getValidationState,
            resetForm,
            searchFilter,
            priceOptions
        };
    },
};
</script>

<style></style>
